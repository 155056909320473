/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import Flex from "../../Atomic/Flex"

export default function CyclingBlocks() {
  return (
    <Flex>
      <motion.div
        sx={{ width: 50, height: 50, backgroundColor: "#ff00e7" }}
        animate={{ x: -100, rotate: 45, backgroundColor: "#ffffff" }}
        transition={{
          ease: "easeOut",
          repeat: Infinity,
          repeatType: "reverse",
          duration: 1,
        }}
      />
      <motion.div
        sx={{ width: 50, height: 50, backgroundColor: "#FFFFFF" }}
        animate={{
          x: 100,
          rotate: -45,
          backgroundColor: "#ff00e7",
        }}
        transition={{
          ease: "easeOut",
          repeat: Infinity,
          repeatType: "reverse",
          duration: 1,
        }}
      />
    </Flex>
  )
}
