/**@jsx jsx */
import { jsx } from "theme-ui"

import Box from "../components/Atomic/Box"
import Flex from "../components/Atomic/Flex"
import Navigation from "../components/Homepage/Index/Navigation"
import CyclingBlocks from "../components/Homepage/Index/CyclingBlocks"
import PageStructure from "../components/PageStructure"

export default function Home({ location }) {
  // console.log("location", location)
  return (
    <PageStructure
      pageTitle={`Kjofs workbench`}
      metaContent={`Author: André Åström, content: Index page for andreeastroem.se acting as a curriculum vitae`}
      location={location}
    >
      <Flex sx={{ paddingY: ["sm", null, "md", "lg"] }} />
      <Flex sx={{ width: "100%", justifyContent: "center" }}>
        <Box sx={{ width: ["100%", null, "50%"] }}>
          <Navigation />
        </Box>
      </Flex>
      <Box py={`md`}>
        <CyclingBlocks />
      </Box>
    </PageStructure>
  )
}
