/** @jsx jsx */
import { jsx } from "theme-ui"
import Flex from "../../Atomic/Flex"

import { Link } from "gatsby"

export default function NavigationLink({ text, to }) {
  return (
    <Flex
      as={Link}
      to={to}
      sx={{
        width: "100%",
        height: ["100px", "125px", "150px", "200px"],
        padding: "3xs",
        "&:hover": {
          padding: 0,
        },
        transition: "padding 0.2s ease",
        textDecoration: "none",
      }}
    >
      <Flex
        sx={{
          // backgroundColor: `greyScale.2`,
          backgroundColor: `lightBackground`,
          borderRadius: "md",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textShadow: "neon",
          "&:hover": { textShadow: "neonFocus" },
        }}
      >
        <Flex
          sx={{
            // color: "greyScale.6",

            fontWeight: "thin",
            transition: "textShadow 0.2s ease",
            fontSize: ["md", "lg", "xl", "h1"],
          }}
        >
          {text}
        </Flex>
      </Flex>
    </Flex>
  )
}
