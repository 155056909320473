/** @jsx jsx */
import { jsx } from "theme-ui"

import Grid from "../../Atomic/Grid"
import NavigationLink from "./NavigationLink"

export default function Navigation() {
  const links = [
    { text: "Projects", to: "/homepage/projects" },
    { text: "Art", to: "/homepage/art" },
    { text: "Blog", to: "/homepage/blog" },
    { text: "Games", to: "/homepage/games" },
  ]
  return (
    <Grid gap={0} columns={[2]} sx={{ width: "100%" }}>
      {links.map(link => (
        <NavigationLink key={link.text} text={link.text} to={link.to} />
      ))}
    </Grid>
  )
}
